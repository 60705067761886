import React from'react';
import Menu from '../util/menu/Menu';

function Skill(){
    return(<div>
        <Menu />
        <h1>Skill</h1>
    </div>)
}

export default Skill;