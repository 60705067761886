import React from'react';
import Menu from '../util/menu/Menu';

function Goals(){
    return (<div>
        <Menu />
        <h1>Goals</h1>
           </div>)
}

export default Goals;