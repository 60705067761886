import React from'react';
import Menu from '../util/menu/Menu';


function Contact(){
    return <div>
        <Menu />
        <h1>Contact</h1>
        </div>;


}


export default Contact;