import React from'react';
import Menu from '../util/menu/Menu';


function Sites(){
    return <div>
        <Menu />
        <div>
        <h1>Sites</h1>
        <ul>
            <li><a href='https://pcolarugby.com'>Rugby</a></li>
            <li><a href='https://tcon.app'>Private App</a></li>
            <li><a href='https://sexysummer14.com'>Friend's Modeling Site</a></li>
        </ul>
        </div>
    </div>
}

export default Sites;   